import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import DOD from '../components/dods';

import Frame1 from '../_images/profiles/teresita-de-la-torre-2.jpg';
import Frame2 from '../_images/profiles/teresita-de-la-torre-3.jpg';
import socialBanner from '../_images/profiles/teresita-de-la-torre-social.jpg';

Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      width: 0,
      height: 0 
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logger = this.logger.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }


  render() {
    var pageTitle = 'Titan of Expression';
    var pageDesc = 'As a master’s student in art at Cal State Fullerton, Teresita de la Torre found her inspiration and herself. With the encouragement of Assistant Professor of Art Rebecca Campbell, Teresita turned a sun-damaged shirt, found discarded near the U.S.-Mexico border, into the catalyst for a life-changing project..';

    // console.log('width: ' + this.state.width );
    var windowHeight = {
      minHeight: this.state.height
    }
    return (
      <React.Fragment>
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="profile-landing-page" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

          <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link>
        </Helmet>
        <section className="landing-wrap teresita-de-la-torre animated fadeIn"style={windowHeight}>
          <div className="profile-text"style={windowHeight}>
            <div className="text-wrap animated fadeIn delay-1s">
              
              <div className="row">
                <h1>{pageTitle}</h1>
                <div className="col-3">  
                  <blockquote>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>“</title><path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"/></svg>
                    <p>“As a child of formerly undocumented parents, I felt a weight and responsibility to honor the shirt and share the story of where I found it. Cal State Fullerton pushed me to not be afraid to do the projects that I want to do — there I found my voice and myself.”</p>
                  </blockquote>
                  <div className="clear"></div>
                </div>
                <div className="col-9">

                  <p>As a master’s student in art at Cal State Fullerton, Teresita de la Torre found her inspiration and herself. With the encouragement of Assistant Professor of Art Rebecca Campbell, Teresita turned a sun-damaged shirt, found discarded near the U.S.-Mexico border, into the catalyst for a life-changing project.</p>

                  <p>Every day for an entire year, she wore the shirt, exploring the possibilities of who might have worn it in the past and under what conditions. As the shirt physically deteriorated, her passion for giving voice to the immigrant experience grew in the body of work she created around the experience. </p>

                  <p>She hopes that future CSUF art students will have a chance to find their inspiration as she did — as the campus transforms spaces with the support of generous donors.</p>

                  <h2>Be the difference in a Titan's life.</h2>
                  <p><strong>Support the visual arts center today.</strong></p>

                  <ul className="actions">
                    <li><a href="https://give.fullerton.edu/campaign/cota-learning-opportunities" className="buttonBlue" onClick={this.logger}>Donate Now</a></li>
                    {/* <li><a href="https://www.fullerton.edu/arts/" className="buttonBlue outline">Learn More</a></li> */}
                    <li><button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button></li>
                  </ul>

                  <DOD college="arts" layout="2" />
                  
                </div>
              </div>
              
            </div>

            

          </div>
          <img src={Frame1} className="frame animated fadeIn" id="frame1" alt="Teresita stands in front of a Chanel store wearing a torn, sun-damaged shirt." />
          <img src={Frame2} className="frame animated fadeIn" id="frame2" alt="Sun-damaged shirt discarded in desert near U.S.-Mexico border." />
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
          shouldCloseOnOverlayClick={true}
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="expression" college="the College of the Arts" />
          </div>
        </Modal>
        
      </Layout>
      </React.Fragment>
    );
  }
}
